// @flow

import React, { PureComponent, Fragment } from 'react'
import { withStyles } from '../../utils'
import styles from './styles'
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from 'material-ui/Table'

export type Parameter = {
  name: string,
  example: string,
  optional?: boolean,
  description: string,
  implemented?: boolean
}

export type Props = {
  classes?: any,
  rows: Array<Parameter>
}

class UIParameterTable extends PureComponent<Props> {
  render = () => {
    const { classes = {}, rows } = this.props
    return (
      <Fragment>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Parameter</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Example</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, i) => (
              <TableRow
                key={i}
                className={
                  r.implemented === false ? classes.rowNotImplemented : ''
                }
              >
                <TableCell>
                  {r.name}
                  {r.optional ? '' : '*'}
                  {r.implemented === false ? <i> (planned)</i> : ''}
                </TableCell>
                <TableCell>{r.description}</TableCell>
                <TableCell>
                  <code>{r.example}</code>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <i style={{ fontSize: 11, paddingLeft: 30 }}>* required parameter</i>
      </Fragment>
    )
  }
}

export default withStyles(styles)(UIParameterTable)
