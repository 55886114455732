// @flow

import React, { Fragment } from "react"
import * as commonRows from "../../shared-docs/user-interface/common-rows"
import UISimpleDoc from "../../components/UISimpleDoc"
import Link from "../../components/Link"
import CollegeCard from "@okcollege/web-mui-components/CollegeCard"
import rpi from "@okcollege/postcore-data/production/colleges/rensselaer-polytechnic-institute.json"
import harvard from "@okcollege/postcore-data/production/colleges/harvard-university.json"
import florida from "@okcollege/postcore-data/production/colleges/university-of-central-florida.json"
import princeton from "@okcollege/postcore-data/production/colleges/princeton-university.json"
import Grid from "material-ui/Grid"

export const code1 = `
<div class="collegeai-college-list"
  data-colleges-per-page="3"
  data-hide-add-to-list="true"
></div>
`

export const code2 = `
<div class="collegeai-college-list"
  data-colleges-per-page="3"
  data-compact-view="true"
></div>
`

export const code3 = `
<div class="collegeai-college-list"
  data-colleges-per-page="3"
  data-show-info-table="true"
  data-info-ids="student-faculty-ratio,average-net-cost"
></div>
`

export const name = "College List"
export const section = "User Interface"
export const component = () => (
  <UISimpleDoc
    largeExample
    title={name}
    description={
      <Fragment>
        <p>
          Display a College List whenever you want to show a list of colleges
          for users to interact with and explore.
        </p>
        <p>
          College Lists can operate alongside a{" "}
          <Link href="/user-interface/filter-view">Filter View</Link> for
          advanced search functionality.
        </p>
        <p>College Lists can display colleges recommended for a user.</p>
      </Fragment>
    }
    parameters={[
      commonRows.userId,
      {
        ...commonRows.collegeIds,
        optional: true,
        implemented: false
      },
      // {
      //   name: 'data-colleges-per-page',
      //   optional: true,
      //   description: 'Number of colleges to show per page. Default is 10.',
      //   example: '10'
      // },
      // {
      //   name: 'data-page-limit',
      //   description:
      //     'Maximium number of pages for user to scroll through. Default is 5.',
      //   optional: true,
      //   example: '5'
      // },
      {
        name: "data-show-recommended",
        description:
          "If specified, colleges will be ordered by their match score for a student.",
        optional: true,
        example: "true"
      },
      {
        name: "show-description",
        description: "Show the college description. Default true.",
        optional: true,
        example: "true"
      },
      {
        name: "show-info-table",
        description:
          'Show info in the college boxes, make sure to define what info you would like to appear with "info-ids." Default false.',
        optional: true,
        example: "false"
      },
      {
        name: "info-ids",
        description:
          'Information ids to show in college card. Make sure to set "show-info-table".',
        optional: true,
        example: "top-undergraduate,student-faculty-ratio"
      },
      // {
      //   name: 'allowed-reason-ids',
      //   description:
      //     'If specified, only pros and cons with the provided reason ids will be shown.',
      //   optional: true,
      //   example: 'closeness-to-home,school-size'
      // },
      {
        name: "data-compact-view",
        description:
          "Show the colleges compactly, without, descriptions and images.",
        optional: true,
        example: "true"
      },
      {
        name: "data-show-student-list",
        description:
          "Only displays the colleges in the student's list. Default value is false, set to true to enable.",
        optional: true,
        example: "false"
      },
      {
        name: "data-hide-add-to-list",
        description:
          "Hides the add to list button. Default value is false (which shows the button), set to true to enable.",
        optional: true,
        example: "true"
      }
    ]}
    examples={[code1, code2, code3]}
  />
)
