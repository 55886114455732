// @flow

import React, { PureComponent, Fragment } from "react"

import PageContent from "../../components/PageContent"
import HalfBlock from "../../components/HalfBlock"
import FullBlock from "../../components/FullBlock"
import UIParameterTable from "../../components/UIParameterTable"
import type { Parameter } from "../UIParameterTable"
import ExampleBox from "../ExampleBox"
import Code from "react-syntax-highlighter"

export type Props = {
  title: string,
  description: any,
  parameters?: Array<Parameter>,
  examples?: Array<string>,
  largeExample?: boolean
}

class UISimpleDoc extends PureComponent<Props> {
  render = () => {
    const {
      title,
      description,
      parameters,
      examples = [],
      largeExample
    } = this.props

    return (
      <PageContent>
        <HalfBlock>
          <h1>{title}</h1>
          {description}
        </HalfBlock>
        {parameters ? (
          <FullBlock>
            <h2>Parameters</h2>
            <UIParameterTable rows={parameters} />
          </FullBlock>
        ) : (
          <FullBlock />
        )}
        <HalfBlock>
          <h2>Example</h2>
        </HalfBlock>
        {examples.map(
          exampleCode =>
            largeExample ? (
              <Fragment>
                <FullBlock>
                  <ExampleBox exampleCode={exampleCode} />
                </FullBlock>
                <FullBlock>
                  <Code>{exampleCode}</Code>
                </FullBlock>
              </Fragment>
            ) : (
              <FullBlock
                left={<ExampleBox exampleCode={exampleCode} />}
                right={<Code>{exampleCode.trim()}</Code>}
              />
            )
        )}
      </PageContent>
    )
  }
}

export default UISimpleDoc
