// @flow

import React, { PureComponent, Fragment } from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'
import FullBlock from '../../components/FullBlock'
import Table from '../../components/Table'
import survey from '@okcollege/postcore-data/production/survey'

const answerTypeIds = [
  'yes-no',
  'yes-no-idk',
  'yes-no-maybe',
  'class-rank',
  'importance',
  'after-college-remarks',
  'likely-lunch-topics',
  'vague-majors',
  'campus-photos',
  'us-region',
  'school-clubs',
  'high-school-jobs',
  'income-buckets',
  'college-status',
  'high-school-class',
  'post-college',
  'college-desire-types',
  'major-categories',
  'weather',
  'extracurricular-activities',
  'movie-type',
  'user-role',
  'school-size',
  'tests-taken',
  'sat-subject-tests',
  'ap-test',
  'study-amount',
  'music-groups',
  'sports',
  'area-type',
  'fast-filters',
  'complex',
  'idea',
  'emotion',
  'standarized-tests',
  'relationship',
  'high-school-class-year',
  'sport-level',
  'institution-faiths',
  'college-list',
  'high-school-list'
]

export const name = 'Survey Answer Types'
export const section = 'Data Types'
export const order = 6

const answerTypes = answerTypeIds.map(atId => ({
  id: atId,
  val: survey.answerTypes[atId]
}))

class AnswerTypes extends PureComponent<*> {
  render = () => {
    const { classes = {} } = this.props
    return (
      <PageContent>
        <HalfBlock>
          <h1>Answer Types</h1>
          <p>
            Each answer type defines what a user can answer for a question. You
            can see all the possible answers for an answer type under each
            bolded answer type id.
          </p>
          <p>
            For example, the <b>yes-no</b> answer type has the possible answers,{' '}
            <b>yes</b> and <b>no</b>.
          </p>
        </HalfBlock>
        {answerTypes.map(({ id, val }, ind) => (
          <FullBlock key={id}>
            <h2>{id}</h2>
            {val.answers && (
              <Table
                header={['Answer Id', 'Answer Text']}
                rows={val.answers.map(({ name, id }) => [id, name])}
              />
            )}
            {val.fetchAnswers && <p>This answer type is fetched remotely</p>}
          </FullBlock>
        ))}
      </PageContent>
    )
  }
}

export const component = () => <AnswerTypes />
export default AnswerTypes
