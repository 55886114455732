// @flow

import React, { Fragment, Component } from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'
import FullBlock from '../../components/FullBlock'
import Table from '../../components/Table'
import Link from '../../components/Link'
import DynamicAPISnippet from '../../components/DynamicAPISnippet'
import ReactMarkdown from 'react-markdown'
import tos from './tos.md'

export const name = 'Terms of Service'
export const section = 'Legal'
export const order = 1
export const component = class TermsOfService extends Component<*, *> {
  state = { tos: '' }
  componentWillMount = async () => {
    this.setState({
      tos: await fetch(tos).then(r => r.text())
    })
  }
  render() {
    return (
      <PageContent>
        <FullBlock>
          <ReactMarkdown source={this.state.tos} />
        </FullBlock>
      </PageContent>
    )
  }
}
