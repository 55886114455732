// @flow

import React, { Fragment } from "react"
import * as commonRows from "../../shared-docs/user-interface/common-rows"

import UISimpleDoc from "../../components/UISimpleDoc"

import rpi from "@okcollege/postcore-data/production/colleges/rensselaer-polytechnic-institute.json"

const code1 = `
<div
  class="collegeai-acceptance-curves"
  data-college-unit-id="194824"
></div>
`.trim()

export const name = "Acceptance Curves"
export const section = "User Interface"
export const component = () => (
  <UISimpleDoc
    title="Acceptance Curves"
    description={
      <Fragment>
        <p>
          Acceptance curves are derived from hundreds of thousands of student
          data points. Each student that applies to a university is given an
          performance score based on all of their tests. The performance score
          formula is learned using a CollegeAI algorithm, and is statistically
          an excellent aggregate score for predicting acceptance probability.
        </p>
        <p>
          Acceptance curves help a student understand where they stand
          academically relative to typical applicants and accepted applicants to
          a college.
        </p>
      </Fragment>
    }
    examples={[code1]}
    parameters={[
      commonRows.userId,
      commonRows.collegeId,
      {
        name: "axis-types",
        optional: true,
        implemented: false,
        example: `performance,sat`,
        description: "Label to show on bottom axis."
      }
    ]}
  />
)
