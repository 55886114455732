// @flow

import React from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'

export const name = 'Overview'
export const section = 'JSON API'
export const order = 1
export const component = () => (
  <PageContent>
    <HalfBlock>
      <h1>JSON API Overview</h1>
      <p>
        If you'd like to cache/store college data on a server, use CollegeAI
        attributes for visualization or statistics purposes outside of what the
        user interface or womdpw api provides, then the JSON api may be useful
        for you.
      </p>
    </HalfBlock>
  </PageContent>
)
