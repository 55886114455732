// @flow

import React, { PureComponent } from "react"
import { withStyles } from "../../utils"
import styles from "./styles"

export type Props = {
  classes?: any,
  exampleCode: string
}

class ExampleBox extends PureComponent<Props> {
  render = () => {
    const { classes = {}, exampleCode } = this.props
    return (
      <div
        dangerouslySetInnerHTML={{ __html: exampleCode }}
        className={classes.exampleBox}
      />
    )
  }
}

export default withStyles(styles)(ExampleBox)
