// @flow

import React, { PureComponent, Fragment } from "react"
import Table from "../../components/Table"
import PageContent from "../../components/PageContent"
import HalfBlock from "../../components/HalfBlock"
import UIParamterTable from "../../components/UIParameterTable"

export const name = "Filter Ids"
export const section = "Data Types"
export const order = 7
const filterModifiers = [
  {
    label: "Admissions",
    id: "admissions",
    ids:
      "sat-overall,act-composite,close-to-my-scores,show-safeties,show-reaches,selectivity,degree-length"
  },
  {
    label: "Type of School",
    id: "type-of-school",
    ids: "degree-length,funding-type,school-size,sex,primary-faith"
  },
  {
    label: "Location",
    id: "location",
    ids: "zip-code,distance-from-home-miles,distance-from-home-drive-time"
  },
  { label: "Campus & Housing", id: "campus-housing", ids: "area-type" },
  // { label: 'Majors', id: 'majors', ids: '' },
  { label: "Sports & Activities", id: "sports-activities", ids: "activites" },
  { label: "Paying", id: "paying", ids: "atleast-percent-financial-aid-met" },
  { label: "Diversity", id: "diversity", ids: "min-percentage-of-minorities" }
]

const filterIds = [
  {
    label: "Your SAT Score",
    id: "sat-overall",
    type: "number"
  },
  {
    label: "Your ACT Composite",
    id: "act-composite",
    type: "number"
  },
  {
    label: "Show schools with applicants close to my scores",
    id: "close-to-my-scores",
    type: "boolean"
  },
  {
    label: "Show good safeties for me",
    id: "show-safeties",
    type: "boolean"
  },
  {
    label: "Show good reaches for me",
    id: "show-reaches",
    type: "boolean"
  },
  {
    label: "Selectivity",
    id: "selectivity",
    type:
      'Array<"notSelective"|"lessSelective"|"selective"|"verySelective"|"mostSelective">'
  },
  {
    label: "Degree Length",
    id: "degree-length",
    type: 'Array<"2year"|"4year">'
  },
  {
    label: "Public or Private",
    id: "funding-type",
    type: 'Array<"public"|"private"|"forprofit">'
  },
  {
    label: "School Size",
    id: "school-size",
    type: "Array<string>"
  },
  {
    label: "Single-sex or Coed",
    id: "sex",
    type: 'Array<"coed"|"women"|"men">'
  },
  {
    label: "Primary Faith",
    id: "primary-faith",
    type: '"christian"|"jewish"|"muslim"|"buddhist"|"hindu"'
  },
  {
    label: "Distance (in miles)",
    id: "distance-from-home-miles",
    type: "range"
  },
  {
    label: "Distance (hours driving)",
    id: "distance-from-home-drive-time",
    type: "range"
  },
  {
    label: "Area Type",
    id: "area-type",
    type: "Array<string>"
  },
  {
    label: "Activities",
    id: "activites",
    type: "Array<string>"
  },
  {
    label: "Min Percent Financial Aid Met (%)",
    id: "atleast-percent-financial-aid-met",
    type: "number"
  },
  {
    label: "Max Tuition",
    id: "max-tuition",
    type: "number"
  },
  {
    label: "Min Percentage of Minority Students",
    id: "min-percentage-of-minorities",
    type: "number"
  },
  {
    label: "ZipCode",
    id: "zip-code",
    type: "text"
  },
  {
    label: "Max In-State Tuition",
    id: "max-in-state-tuition",
    type: "number"
  },
  {
    label: "Max Out-of-State Tuition",
    id: "max-out-of-state-tuition",
    type: "number"
  },
  {
    label: "In State",
    id: "in-state",
    type: "State Abbreviation e.g. NY, MA, NJ"
  },
  {
    label: "Athletic Conference",
    id: "athletic-conference",
    type:
      "NCAA D1, NCAA D2, NCAA D3, NCAA Associate Member, NCAA, NJCAA, NJCAA D1, NJCAA D2, NJCAA D3, NAIA, NAIA D1, NAIA D2, NCCAA, Independent, CCCAA, NWAC, USCAA"
  },
  {
    label: "Number of Enrolled Students",
    id: "number-enrolled-students",
    type: "[min, max] e.g. [0, 10000]"
  },
  {
    label: "Application Requires GPA",
    id: "application-requires-gpa",
    type: "boolean"
  },
  {
    label: "Does this student have a higher gpa than the 10th percentile?",
    id: "gpa-minimum-ten-percent",
    type: "number"
  },
  {
    label: "College Name",
    id: "name",
    type: "string"
  }
]

class FilterIDs extends PureComponent<Props> {
  render = () => {
    const { classes = {} } = this.props
    return (
      <PageContent>
        <HalfBlock>
          <h1>Filter Ids</h1>
          <p>The table below shows all filter ids.</p>
        </HalfBlock>
        <Table
          header={["Filter Id", "Name", "Format"]}
          rows={filterIds.map(({ id, label, type }) => [id, label, type])}
        />
        <HalfBlock>
          <h1>Filter Modifiers</h1>
          <p>
            These modifiers only take effect if a filter above is being used.
          </p>
        </HalfBlock>
        <Table
          header={["Filter Id", "Name", "Modifier Filter Ids"]}
          rows={filterModifiers.map(({ id, label, ids }) => [id, label, ids])}
        />
      </PageContent>
    )
  }
}

export const component = () => <FilterIDs />
export default FilterIDs
