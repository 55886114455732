// @flow

import React from "react"
import PageContent from "../../components/PageContent"
import HalfBlock from "../../components/HalfBlock"
import UIParamterTable from "../../components/UIParameterTable"

export const name = "Info Ids"
export const section = "Data Types"
export const order = 4
const infoStrings = [
  // ----- General Info  -----
  ["aliases", '["MIT", "M.I.T."]'],
  ["campusImage", ""],
  ["logoImage", ""],
  ["colors", ["red"]],
  ["isPrivate"],
  ["fundingType", "not-for-profit"],
  ["localeClass"],
  ["localeSize"],
  ["website", "web.mit.edu/student"],
  ["financialAidWebsite", "web.mit.edu/student"],
  [
    "shortDescription",
    `The Massachusetts Institute of Technology (MIT) is a private research university in Cambridge, Massachusetts, often cited as one of the world's most prestigious universities.`
  ],
  [
    "longDescription",
    `The Massachusetts Institute of Technology (MIT) is a private research university in Cambridge, Massachusetts, often cited as one of the world's most prestigious universities.Founded in 1861 in response to the increasing industrialization of the United States, MIT adopted a European polytechnic university model and stressed laboratory instruction in applied science and engineering. Researchers worked on computers, radar, and inertial guidance during World War II and the Cold War. Post-war defense research contributed to the rapid expansion of the faculty and campus under James Killian. The current 168-acre (68.0 ha) campus opened in 1916 and extends over 1 mile (1.6 km) along the northern bank of the Charles River basin.The Institute is traditionally known for its research and education in the physical sciences and engineering, and more recently in biology, economics, linguistics, and management as well. For several years, MIT's School of Engineering has been ranked first in various international and national university rankings, and the Institute is also often ranked among the world's top universities overall. The "Engineers" compete in 31 sports, most teams of which compete in the NCAA Division III's New England Women's and Men's Athletic Conference; the Division I rowing programs compete as part of the EARC and EAWRC.As of 2015, 85 Nobel laureates, 52 National Medal of Science recipients, 65 Marshall Scholars, 45 Rhodes Scholars, 38 MacArthur Fellows, 34 astronauts, 19 Turing award winners, 16 Chief Scientists of the U.S. Air Force and 6 Fields Medalists have been affiliated with MIT. The school has a strong entrepreneurial culture, and the aggregated revenues of companies founded by MIT alumni would rank as the eleventh-largest economy in the world.`
  ],

  // ----- Related to Location  -----
  ["city", "Cambridge"],
  ["stateAbbr", "MA"],
  ["locationLat", -71.092115],
  ["locationLong", 42.360095],

  // ----- Related to Admissions  -----
  ["branchCampuses", "['Rensselaer-hartford-graduate-center']"],
  ["typeYear", "4-year"],
  ["typeCategory", "private"],
  ["region", '"Mid East (DE, DC, MD, NJ, NY, PA)"'],
  ["menOnly"],
  ["womenOnly"],
  ["religiousAffiliation"],
  ["primaryFaith"],

  // ----- Related to Admissions  -----

  ["satMathMidpoint"],
  ["satReadingMidpoint"],
  ["satCompositePercentile25"],
  ["satCompositePercentile75"],
  ["satCompositeMidpoint"],
  ["satAverage"],
  ["satMathPercentile25", 400],
  ["satMathPercentile75", 700],
  ["satReadingPercentile25", 400],
  ["satReadingPercentile75", 700],
  ["actCumulativeMidpoint"],
  ["actCumulativePercentile25"],
  ["actCumulativePercentile75"],
  ["actMathPercentile25", 24],
  ["actMathPercentile75", 32],
  ["actEnglishPercentile25", 24],
  ["actEnglishPercentile75", 32],
  ["actSciencePercentile25", 24],
  ["actSciencePercentile75", 32],
  ["actWritingPercentile25", 24],
  ["actWritingPercentile75", 32],

  ["studentsSubmittingACT", 0.37],
  ["studentsSubmittingSAT", 0.63],

  ["acceptanceRate", 0.534],
  ["earlyDecisionAcceptanceRate", 0.534],
  ["totalApplicants", 18524],

  ["usesWaitList", "true"],
  ["allowsTransferAdmission", "true"],

  ["applicationDeadline", "{ month: 1, day: 20 }"],
  ["applicationFee", 70],

  ["offersEarlyDecision", "true"],
  ["offersEarlyAction", "false"],
  ["earlyDecisionDeadline", "{ month: 10, day: 1 }"],
  ["earlyActionDeadline", "{ month: 10, day: 1 }"],
  ["offersEarlyDecisionDeadline", true],
  ["offersEarlyActionDeadline", false],
  ["acceptsCommonApp", true],
  ["acceptsCoalitionApp", false],

  ["hsGpaRequirement", "required"],
  ["gpaBottomTenPercent", 3.4],
  ["hsRankRequirement", "neither"],
  ["secondarySchoolRecordRequirement", "recommended"],
  ["collegePrepCourses", "recommended"],
  ["recommendationsRequirement", "recommended"],
  ["toeflRequirement"],
  ["interviewRequirement", "not required"],
  ["satOrActRequirement", "required"],
  ["satRequirement", ""],
  ["satActRequirement", ""],
  ["satSubjectTests", "required"],

  ["admissionsWebsite", "web.mit.edu/student"],
  ["applicationWebsite", "web.mit.edu/student"],

  // ----- Related to Students  -----

  ["demographicsMen"],
  ["demographicsWomen"],
  ["percentFirstGeneration"],
  ["demographicsShare25Older"],
  ["undergraduateSize"],
  ["totalEnrolled"],

  ["studentCountByIncomeLevel0To30000"],
  ["studentCountByIncomeLevel30001To48000"],
  ["studentCountByIncomeLevel48001To75000"],
  ["studentCountByIncomeLevel75001To110000"],
  ["studentCountByIncomeLevel110001Plus"],

  ["studentAvgFamilyIncome"],
  ["studentMedianFamilyIncome"],
  ["studentMedianHouseholdIncome"],

  ["averageAgeOfEntry", 21],
  ["percentInternational", 0.113],

  [
    "studentPercentageRaceEthnicity",
    "{ white: 0.3, black: 0.3, hisp: 0.3, asian: 0.3, aian: 0.3, nhpi: 0.3, 2mor: 0.3, nra: 0.3, unkn: 0.3 }"
  ],

  // ----- Related to Cost  -----

  ["avgCostOfAttendance"],
  ["avgNetPrice"],
  ["typicalFinancialAid"],

  ["federalLoanRate"],
  ["loanPrincipal"],
  ["studentsWithAnyLoan"],

  ["percentStudentsReceivingFederalGrantAid"],
  ["averageFederalGrantAid"],
  ["percentStudentsReceivingStateGrantAid"],
  ["averageStateGrantAid"],
  ["percentStudentsReceivingInstitutionGrantAid"],
  ["averageInstitutionGrantAid"],
  ["percentStudentsReceivingPellGrant"],
  ["averagePellGrantAmount"],

  ["netPriceByIncomeLevel0To3000"],
  ["netPriceByIncomeLevel30001To48000"],
  ["netPriceByIncomeLevel48001To75000"],
  ["netPriceByIncomeLevel75001To110000"],
  ["netPriceByIncomeLevel110001Plus"],

  ["inStateTuition"],
  ["outOfStateTuition"],

  ["averageBooksAndSuppliesCost"],

  ["tuitionGuaranteePlan"],
  ["tuitionPaymentPlan"],
  ["prepaidTuitionPlan"],

  ["percentUndergradsAwardedAid", 88],
  ["averageFinancialAid", 27478],

  ["typical10YearEarnings", 61400],
  ["typical6YearEarnings", 79600],

  ["undergradApplicationFee", 70],
  ["typicalRoomAndBoard", 79600],
  ["typicalBooksAndSupplies", 79600],
  ["typicalMiscExpenses", 79600],

  [
    "averageAidAwardedLowIncome",
    "Yearly aid awarded to families with a household income of $0-$10,000",
    ""
  ],
  [
    "averageAidAwardedLowerMiddleIncome",
    "Yearly aid awarded to families with a household income of $10,000-$30,000",
    ""
  ],
  [
    "averageAidAwardedMiddleIncome",
    "Yearly aid awarded to families with a household income of $30,000-48,000",
    ""
  ],
  [
    "averageAidAwardedUpperMiddleIncome",
    "Yearly aid awarded to families with a household income of $48,000-$75,000",
    ""
  ],
  [
    "averageAidAwardedHighIncome",
    "Yearly aid awarded to families with a household income of more than $75,000",
    ""
  ],

  ["enrolledStudents"],
  ["enrolledMen"],
  ["enrolledWomen"],
  ["gpaRequirement", "true"],
  ["athleticConference", "NCAA Division III"],

  // ----- Related to Academics  -----

  ["fullTimeRetentionRate", 93],
  ["partTimeRetentionRate", "known"],
  ["calendarSystem", "semester"],
  ["offersSummerSession", "false"],
  ["researchFundingPerStudent", 12000],

  ["offersEveningDegreePrograms", "true"],
  ["offersTeacherCertification", "false"],
  ["offersDistanceEducation", "unknown"],
  ["offersStudyAbroad", "true"],

  [
    "classSizeRangeOver100",
    "Percentage of students in classes sized 100+ students"
  ],
  [
    "classSizeRange2To9",
    "Percentage of students in classes sized 2 to 9 students"
  ],
  [
    "classSizeRange10To19",
    "Percentage of students in classes sized 10 to 19 students"
  ],
  [
    "classSizeRange20To29",
    "Percentage of students in classes sized 20 to 29 students"
  ],
  [
    "classSizeRange30To39",
    "Percentage of students in classes sized 30 to 39 students"
  ],
  [
    "classSizeRange40To49",
    "Percentage of students in classes sized 40 to 49 students"
  ],
  [
    "classSizeRange50To99",
    "Percentage of students in classes sized 50 to 99 students"
  ],
  [
    "academicsProgramPercentage",
    "{ resources: 0.0018, architecture: 0.0399, communication: 0.0213 }"
  ],

  [
    "academicMajorGraduates",
    "{arts: { gameAndInteractiveMediaDesign: 10 }, business: { businessAdministrationAndManagement: 24 }, humanities: { cognitiveScience: 2, communications: 55, economics: 42 }"
  ],

  ["studentFacultyRatio", 14],
  ["femaleProfessors", 0.25],
  ["maleProfessors", 0.75],
  ["averageProfessorSalary", 149000],
  ["numberOfFullTimeFaculty"],
  ["numberOfPartTimeFaculty"],

  // ----- Related to campus life  -----
  ["onCampusHousingAvailable", "true"],
  ["freshmenRequiredToLiveOnCampus", "true"],
  ["freshmenLiveOnCampus", 0.72],
  ["undergradsInCollegeHousing", 0.52],
  ["averageHousingCost", 8310],

  ["mealPlanAvailable", "true"],
  ["averageMealPlanCost", 6300],

  ["athleticsConference"],
  ["athleticsMascot"],

  [
    "menVarsitySports",
    "['Baseball', 'Soccer', 'Lacrosse', 'Football', 'All Track Combined']"
  ],
  [
    "womenVarsitySports",
    "['Baseball', 'Soccer', 'Lacrosse', 'Football', 'All Track Combined']"
  ],

  // ----- Student Activities  -----

  ["sororitiesPercentParticipation", 0.33],
  ["fraternitiesPercentParticipation", 0.31],
  ["rotcArmy", "true"],
  ["rotcAirForce", "true"],
  ["rotcNavy", "true"],
  [
    "activitiesandorganizations",
    "['dance', 'musicensembles', 'studentrunfilmsociety', 'dramatheater', 'musicaltheater', 'symphonyorchestra', 'internationalstudentorganization', 'opera']"
  ],
  // ----- Related to after college  -----

  ["medianEarningsTenYrsAfterEntry"],
  ["medianEarningsSixYrsAfterEntry"],

  ["employedTwoYearsAfterGraduation", 0.87],
  ["employedSixYearsAfterGraduation", 0.96],
  ["loanDefaultRate", 0.01],
  ["totalEnrolled"],
  ["graduatesPursuingAdvancedStudyDirectly", 0.5],

  // ----- other  -----
  ["fourYearGraduationRate"],
  ["percentOfStudentsWhoReceiveFinancialAid"],

  [
    "completionRate4yr150nt",
    "Number of students that complete a 4 year degree in 6 years"
  ],

  // ----- Related to reviews  -----
  // ['reviewsCount', 100], // Number of reviews
  // ['reviewsRating', 3.5], // Out of 5 from 0 to 5 w/ steps of 0.5

  // ----- Related to rankings  -----

  ["rankingsBestColleges", "{value: 1, total: 1200}"],
  ["rankingsBestValueColleges", "{value: 1, total: 1200}"],
  ["rankingsBestCollegeAcademics", "{value: 1, total: 1200}"],
  ["rankingsTopPrivate-universities", "{value: 1, total: 1200}"],
  ["rankingsMostDiverseColleges", "{value: 1, total: 1200}"],
  ["rankingsBestCollegeCampuses", "{value: 1, total: 1200}"],
  ["rankingsBestCollegeAthletics", "{value: 1, total: 1200}"],
  ["rankingsBestCollegesForBiology", "{value: 1, total: 1200}"],
  ["rankingsBestCollegesForBusiness", "{value: 1, total: 1200}"],
  ["rankingsBestCollegeFood", "{value: 1, total: 1200}"],
  ["rankingsTopPartySchools", "{value: 1, total: 1200}"],
  ["rankingsBestCollegeLocations", "{value: 1, total: 1200}"],
  ["rankingsBestCollegeProfessors", "{value: 1, total: 1200}"],
  ["rankingsBestStudentLife", "{value: 1, total: 1200}"],
  ["rankingsHardestToGetIn", "{value: 1, total: 1200}"],
  ["rankingsBestStudentAthletes", "{value: 1, total: 1200}"],
  ["rankingsHottestGuys", "{value: 1, total: 1200}"],
  ["rankingsBestCatholicColleges", "{value: 1, total: 1200}"],
  ["rankingsCollegesThatAcceptTheCommonApp", "{value: 1, total: 1200}"],
  ["rankingsBestCollegesForChemistry", "{value: 1, total: 1200}"],
  ["rankingsBestCollegesForCommunications", "{value: 1, total: 1200}"],
  ["rankingsBestCollegesForComputerScience", "{value: 1, total: 1200}"],
  ["rankingsBestCollegesForEconomics", "{value: 1, total: 1200}"],
  ["rankingsBestCollegesForHistory", "{value: 1, total: 1200}"],
  ["rankingsBestCollegesForNursing", "{value: 1, total: 1200}"],
  ["rankingsMostLiberalColleges", "{value: 1, total: 1200}"],
  ["rankingsMostConservativeColleges", "{value: 1, total: 1200}"],
  ["rankingsBestCollegesForArt", "{value: 1, total: 1200}"],
  ["rankingsBestCollegesForEngineering", "{value: 1, total: 1200}"],
  ["rankingsBestGreekLifeColleges", "{value: 1, total: 1200}"],
  ["rankingsCollegesWithNoApplicationFee", "{value: 1, total: 1200}"],
  ["rankingsBestCollegesForDesign", "{value: 1, total: 1200}"],
  ["rankingsBestTestOptionalColleges", "{value: 1, total: 1200}"],
  ["rankingsBestCollegesForPhysics", "{value: 1, total: 1200}"],

  // ----- Other  -----

  [
    "similarColleges",
    "College Ids for colleges that are similar to this college",
    "['rensselaer-polytechnic-institute', 'harvard-university']"
  ],
  [
    "theprincetonreviewbest382colleges2018",
    "This college appears in Princeton Review 2018",
    "true"
  ],
  [
    "collegesthatchangelivesfourthedition",
    "This college appears in Colleges that change lives fourth edition",
    "true"
  ]
]
  .sort((a, b) => a[0].localeCompare(b[0]))
  .map(ar => {
    let infoId = "",
      example = "",
      description = ""
    if (ar.length === 1) {
      infoId = ar[0]
    } else if (ar.length === 2) {
      infoId = ar[0]
      example = ar[1]
    } else if (ar.length === 3) {
      infoId = ar[0]
      description = ar[1]
      example = ar[2]
    }
    if (!description) {
      description = infoId
        .replace(/([A-Z])/g, " $1")
        .split(" ")
        .map(w => w.charAt(0).toUpperCase() + w.slice(1))
        .join(" ")
    }
    return [
      infoId.replace(/([A-Z])/g, "_$1").toLowerCase(),
      description,
      example
    ]
  })
export const component = () => (
  <PageContent>
    <HalfBlock>
      <h1>Info Ids</h1>
      <p>The table below shows all the college info ids.</p>
    </HalfBlock>
    <UIParamterTable
      rows={infoStrings.map(([infoId, description, example]) => ({
        name: infoId,
        description,
        optional: true,
        example
      }))}
    />
  </PageContent>
)
