// @flow

import React from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'

// export const name = 'Events'
// export const section = 'Browser API'
// export const order = 2
// export const component = () => (
//   <PageContent>
//     <HalfBlock>
//       <h1>Events</h1>
//       <p>In progress.</p>
//     </HalfBlock>
//   </PageContent>
// )
