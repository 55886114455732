// @flow

export const userId = {
  name: 'data-user-id',
  example: '96565746-b7b4-41c1-9af4-340db2af2196',
  optional: true,
  description:
    'String representing a user. This is a unique key and will allow a user to reaccess any information they uploaded to CollegeAI. User IDs do not persist across different services (e.g. the user will not be able to access their details on CollegeAI.com).'
}

export const collegeId = {
  name: 'data-college-unit-id',
  example: '194824',
  optional: false,
  description:
    'Unit ID for college. Automatically taken from session data if available.'
}

export const collegeIds = {
  name: 'data-college-unit-ids',
  example: '194824, 190415',
  optional: false,
  description: 'Unit IDs for colleges to display.'
}
