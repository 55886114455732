// @flow

import React, { Fragment } from "react"
import * as commonRows from "../../shared-docs/user-interface/common-rows"
import UISimpleDoc from "../../components/UISimpleDoc"

// export const code1 = `
// <div class="collegeai-survey"
//   data-results-href="#"
//   data-questions-per-page="4"></div>
// `
//
export const code2 = `
<!-- Survey with ALL questions --!>
<div class="collegeai-survey"
  data-question-ids="major-category,major-importance,play-sports,sport-level,post-college,high-school-location,high-school-unweighted-gpa-value,high-school-unweighted-gpa-total,high-school-weighted-gpa,class-rank,class-size,tests-taken,sat-subject-tests,ap-tests,favorite-class,fast-filters,faith,study-amount,challenging-offerings,prefer-diversity,close-to-home,preferred-region,care-about-weather,living-area-type,weather,hot-guy-importance,hot-girl-importance,gender-ratio-importance,parents-college,parent-colleges,high-school-name,favorite-movie,play-sports-importance,watch-sports-importance,safety-importance,college-professors,importance-greek-life,importance-highly-ranked,applied-status,importance-low-cost,prefer-public,school-size,household-income,dorm-quality,food-quality,party-scene"
  data-questions-per-page="4"></div>
`

export const name = "Survey"
export const section = "User Interface"
export const component = () => (
  <UISimpleDoc
    title={name}
    description={
      <Fragment>
        <p>
          CollegeAI provides recommendations based on the answers to user
          profile questions, you can ask all these questions in one place using
          the Survey.
        </p>
      </Fragment>
    }
    examples={[code2]}
    parameters={[
      commonRows.userId,
      {
        name: "data-question-ids",
        description:
          "Questions to appear in survey. If not selected, a subset of CollegeAI's full survey will be used.",
        optional: true,
        example: "high-school-zip-code,high-school-gpa"
      },
      {
        name: "data-results-href",
        description: "The url to go to when the survey is submitted",
        optional: false,
        example: "/results"
      },
      {
        name: "data-questions-per-page",
        description:
          "The number of questions to appear on each 'page'. If left undefined, all the questions will appear stacked on top of each other. Else, a next and prev button will appear for pagination.",
        optional: true,
        example: "4"
      }
    ]}
  />
)
