// @flow

import * as React from 'react'

class CenteredContent extends React.PureComponent<{ children?: any }> {
  render() {
    const { children } = this.props
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
        <div style={{ flexGrow: 1, maxWidth: 1600 }}>{children}</div>
      </div>
    )
  }
}

export default CenteredContent
