// @flow

import React, { Fragment } from 'react'
import UISimpleDoc from '../../components/UISimpleDoc'

export const code = `
<div
  class="collegeai-filter-view"
></div>
`

export const name = 'Filter View'
export const section = 'User Interface'
export const component = () => (
  <UISimpleDoc
    title={name}
    description={
      <Fragment>
        <p>
          Interactive filters that will automatically adjust any college lists
          on the page. Disabled fields are blurred and do not function. Hidden
          fields are removed and not displayed to the user. Both the hidden and
          disabled filters can be filter ids, but the hidden-filters field can
          also take in filter category ids.
        </p>
      </Fragment>
    }
    examples={[
      `<div class="collegeai-filter-view"></div>`,
      `<div class="collegeai-filter-view" data-disabled-filters="sat-overall,close-to-my-scores"></div>`,
      `<div class="collegeai-filter-view" data-hidden-filters="admissions,campus-housing,sat-overall,close-to-my-scores"></div>`
    ]}
    parameters={[
      {
        name: 'data-user-id',
        description:
          'If a user id is given, filter options such as "distance-from-home" can be used.',
        optional: true,
        example: 'a847c3048deb'
      },
      {
        name: 'data-disabled-filters',
        description:
          'By default common student filters are shown. To show more filters or to remove filters from selection, manually specify filter ids. See table below for filter ids.',
        optional: true,
        example: 'sat-overall,close-to-my-scores'
      },
      {
        name: 'data-hidden-filters',
        description:
          'By default no filters are hidden. To hide filters or filter categories, manually specify filter ids or filter categories. See table below for filter ids.',
        optional: true,
        example: 'admissions,campus-housing,sat-overall,close-to-my-scores'
      }
    ]}
  />
)
