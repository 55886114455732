// @flow

import React from 'react'
import Grid from 'material-ui/Grid'

export default ({ left, right, children }: any) =>
  children ? (
    <Grid item xs={12}>
      {children}
    </Grid>
  ) : (
    [
      <Grid key="separator" item xs={12} />,
      <Grid key="left" item xs={12} lg={6}>
        {left}
      </Grid>,
      <Grid key="right" item xs={12} lg={6}>
        {right}
      </Grid>
    ]
  )
