// @flow

import React, { Fragment } from 'react'
import * as commonRows from '../../shared-docs/user-interface/common-rows'
import UISimpleDoc from '../../components/UISimpleDoc'

export const code = `
<div
  class="collegeai-compare-college-view"
  data-college-unit-ids="243744,190415"
></div>
`

export const name = 'Compare College View'
export const section = 'User Interface'
export const component = () => (
  <UISimpleDoc
    title={name}
    description={
      <Fragment>
        <p>
          Displays college information compared side by side. Show this to
          students who are interested in several colleges and would like to see
          differences between colleges easily.
        </p>
      </Fragment>
    }
    examples={[code]}
    parameters={[
      commonRows.collegeIds,
      {
        name: 'data-show-college-selection',
        description:
          'If specified, a user will be able to add and search for more colleges.',
        optional: true,
        example: 'true'
      }
    ]}
  />
)
