// @flow

import React, { PureComponent } from 'react'
import Grid from 'material-ui/Grid'

class HalfBlock extends PureComponent<{ children: any }> {
  render = () => {
    const { children } = this.props
    return (
      <Grid item md={12} lg={6}>
        {children}
      </Grid>
    )
  }
}

export default HalfBlock
