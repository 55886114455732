// @flow

import React, { PureComponent } from 'react'
import { withStyles } from '../../utils'
import styles from './styles'
import Button from 'material-ui/Button'
import classnames from 'classnames'
import { getContext } from 'recompose'
import PropTypes from 'prop-types'

type OptionalParams = {|
  children: any,
  button?: boolean,
  style?: Object,
  className?: string,
  linkProviderOnClick?: (url: string) => void
|}

export type Props =
  | {
      ...OptionalParams,
      href: string
    }
  | {
      ...OptionalParams,
      onClick: Function
    }

class Link extends PureComponent<Props> {
  onClick = () => {
    window.location.href = this.props.href
  }

  render = () => {
    const classes = (this.props: any).classes
    let {
      children,
      href,
      className,
      button,
      onClick,
      linkProviderOnClick
    } = this.props

    if (linkProviderOnClick && href && !onClick) {
      onClick = () => linkProviderOnClick(href)
    }

    if (href && !button) {
      return (
        <a
          href={!onClick ? href : undefined}
          onClick={onClick}
          className={classnames(classes.a, className)}
        >
          {children}
        </a>
      )
    }
    let onClickOrHref = onClick || this.onClick
    return (
      <Button className={(classes.button, className)} onClick={onClickOrHref}>
        {children}
      </Button>
    )
  }
}

export default getContext({ linkProviderOnClick: PropTypes.func })(
  withStyles(styles)(Link)
)
