// @flow

import React, { Fragment } from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'
import FullBlock from '../../components/FullBlock'
import Table from '../../components/Table'
import Link from '../../components/Link'
import ReactJSON from 'react-json-view'
import Code from 'react-syntax-highlighter'
import Markdown from 'react-markdown'

import {
  getCollegeAutocomplete,
  searchColleges,
  getCollegeInfo,
  getCollegeInfoUsingUnitIds,
  getCollegeInfoUsingNames
} from './sample-outputs'

const MethodDoc = ({ src, output, text, method }) => (
  <Fragment>
    <FullBlock>
      <h2>{method}</h2>
    </FullBlock>
    <FullBlock
      left={<Markdown>{text.trim()}</Markdown>}
      right={
        <Fragment>
          <Code language="javascript">{src}</Code>
          <ReactJSON
            src={output}
            displayDataTypes={false}
            collapsed
            name="print output"
          />
        </Fragment>
      }
    />
  </Fragment>
)

export const name = 'Overview'
export const section = 'Browser API'
export const order = 1
export const component = () => (
  <PageContent>
    <HalfBlock>
      <h1>Browser API Overview</h1>
      <p>
        The browser api is accessed via <code>window.collegeai</code> in a
        browser when the CollegeAI script bundle is included (if you are able to
        use the User Interface, you already have access to the Browser API).
      </p>
      <p>
        The methods on the <code>window.collegeai</code> object are documented
        below.
      </p>
    </HalfBlock>
    <MethodDoc
      method="getCollegeInfo()"
      src={getCollegeInfo.src}
      output={getCollegeInfo.output}
      text={`
Get college information for a set of colleges using semantic ids. A single semantic id or an array of semantic ids may be specified.
`}
    />
    <MethodDoc
      method="getCollegeInfoUsingUnitIds()"
      src={getCollegeInfoUsingUnitIds.src}
      output={getCollegeInfoUsingUnitIds.output}
      text={`
Get college information for a set of colleges using unit ids. A single unit id or an array of unit ids may be specified.
`}
    />
    <MethodDoc
      method="getCollegeInfoUsingNames()"
      src={getCollegeInfoUsingNames.src}
      output={getCollegeInfoUsingNames.output}
      text={`
Get college information for a set of colleges using names or abbreviations. A name or an array of names may be specified.
`}
    />
    <MethodDoc
      method="getCollegeAutocomplete()"
      src={getCollegeAutocomplete.src}
      output={getCollegeAutocomplete.output}
      text={`
Search for colleges using a name or abbreviation. This is most typically used for autocomplete fields.

The returned objects may have a field \`matchingIndicies\` which indicate what part of the college name matches the search query.
`}
    />
    <MethodDoc
      method="searchColleges()"
      src={getCollegeAutocomplete.src}
      output={getCollegeAutocomplete.output}
      text={`
Search for colleges using a filter. You can filter colleges using any of the filters specified in [filter ids section](/data-types/filter-ids).
`}
    />
  </PageContent>
)
