// @flow

import React from "react"
import PageContent from "../../components/PageContent"
import HalfBlock from "../../components/HalfBlock"
import FullBlock from "../../components/FullBlock"
import Table from "../../components/Table"
import Link from "../../components/Link"

export const name = "Endpoints"
export const section = "JSON API"
export const order = 2
export const component = () => (
  <PageContent>
    <HalfBlock>
      <h1>JSON API Endpoints</h1>
      <p>
        This page contains the documentation for each endpoint, but if you're
        just getting started we recommend taking a look at one of the guides
        e.g.{" "}
        <Link href="/json-api/get-college-information">
          Get College Information
        </Link>. You can see all the guides on the sidebar under JSON API.
      </p>
      <p>
        Note that all endpoints return JSON, even in the case of an error. The
        response will always have a <code>true</code> or <code>false</code> key
        named <code>success</code> e.g.{" "}
        <code>
          {"{"} "success": false, "message": "API Key was not provided" {"}"}
        </code>
      </p>
      <p>Every request must include your API key.</p>
    </HalfBlock>
    <Table
      header={["Endpoint", "Description"]}
      rows={[
        [
          `/v1/api/user/colleges`,
          "Retrieve or add colleges from/to a user's list."
        ],
        [
          `/v1/api/user/attribute`,
          "Set or retrieve attributes associated with a user. e.g. whether or not they've applied to colleges."
        ],
        [
          `/v1/api/survey`,
          "Set or retrieve a user's survey or a blank survey."
        ],
        [`/v1/api/college/info`, "Get information for a college"],
        [
          `/v1/api/college-list`,
          "Get a list of colleges matching a filter (e.g. small schools, schools near a zip code) optionally sorted by a criteria (e.g. best match, cost)."
        ],
        [
          `/v1/api/autocomplete/zip-code`,
          "Autocomplete endpoint for zip codes/locations. Great for allowing a user to search for their location."
        ],
        [
          `/v1/api/autocomplete/high-schools`,
          "Autocomplete endpoint for high schools. Great for high school search fields."
        ],
        [
          `/v1/api/autocomplete/colleges`,
          "Autocomplete endpoint for colleges. Great for college search fields."
        ]
      ].map(([endpoint, description]) => [
        <a href={`#${endpoint}`}>{endpoint}</a>,
        description
      ])}
    />
    <FullBlock>
      <h2 id="/v1/api/college/info">GET /v1/api/college/info</h2>
      <p>
        Get information for one or more colleges. See the{" "}
        <Link href="/json-api/guide--get-college-information">
          college information guide
        </Link>{" "}
        for examples.
      </p>
      <p>
        Note that one of <code>college_names</code>, <code>college_ids</code> or{" "}
        <code>college_unit_ids</code> must be provided.
      </p>
      <h3>Request Parameters</h3>
      <Table
        header={["Parameter", "Description", "Example"]}
        rows={[
          [
            "*info_ids",
            "Comma-delimited information ids",
            "student_faculty_ratio,in_state_tuition"
          ],
          [
            "college_ids",
            "Comma-delimited semantic ids for each college to query for.",
            "194824,166683"
          ],
          [
            "college_unit_ids",
            "Comma-delimited unit ids for each college to query for.",
            "194824,166683"
          ],
          [
            "college_names",
            "Names or abbreviations for colleges to query for.",
            "MIT,Rensselaer Polytechnic"
          ]
        ]}
      />
    </FullBlock>
    <FullBlock>
      <h2 id="/v1/api/user/colleges">GET /v1/api/user/colleges</h2>
      <p>
        Query for colleges on a user's list, or predictions for this user based
        off saved information.
      </p>
      <h3>Request Parameters</h3>
      <Table
        header={["Parameter", "Description", "Example"]}
        rows={[
          [
            "*user_id",
            "User Id for the target user. Can be any string.",
            "ABCDEF-GHIJJK-LMNO-PQRSTUV"
          ],
          [
            "*info_ids",
            "Comma-delimited information ids",
            "student_faculty_ratio,in_state_tuition"
          ],
          [
            "prediction",
            "true to get a prediction for colleges that will fit this user, otherwise the user's saved list of colleges will be returned.",
            "true"
          ]
        ]}
      />
    </FullBlock>
    <FullBlock>
      <h2 id="/v1/api/user/attribute">GET /v1/api/user/attribute</h2>
      <p>Get a saved attribute of a user.</p>
      <h3>Request Parameters</h3>
      <Table
        header={["Parameter", "Description", "Example"]}
        rows={[
          [
            "*attribute_keys",
            "Comma-delimited string with each attribute to retrieve from a user.",
            "survey,some_custom_meta_data"
          ],
          [
            "*user_id",
            "User id for the target user. Can be any string.",
            "ABCDEF-GHIJJK-LMNO-PQRSTUV"
          ]
        ]}
      />
      <h2>POST /v1/api/user/attribute</h2>
      <p>Save an attribute to a user.</p>
      <h3>Request Parameters</h3>
      <Table
        header={["Parameter", "Description", "Example"]}
        rows={[
          [
            "*attribute_map",
            "JSON object where each key corresponds to an attribute to be set.",
            '{"custom_info": "Something about the user"}'
          ],
          [
            "*user_id",
            "User id for the target user. Can be any string.",
            "ABCDEF-GHIJJK-LMNO-PQRSTUV"
          ]
        ]}
      />
    </FullBlock>
    <FullBlock>
      <h2 id="/v1/api/college-list">GET /v1/api/college-list</h2>
      <p>
        Get a list of colleges matching filters or recommendations with reasons
        why a student would like/dislike each college.
      </p>
      <h3>Request Parameters</h3>
      <Table
        header={["Parameter", "Description", "Example"]}
        rows={[
          ["limit", "Maximium number of colleges to return, max is 20", "20"],
          [
            "offset",
            "Use this when loading more on page scroll. Can be used to get more than the limit of coleges with multiple requests. Specifies what index to begin returning colleges at.",
            "10"
          ],
          [
            "*info_ids",
            "Comma-delimited information ids",
            "student_faculty_ratio,in_state_tuition"
          ],
          [
            "allowed_reason_ids",
            "Reason Ids to allow in the pros/cons list",
            "See Reason Ids"
          ],
          [
            "filters",
            "JSON object representing filters to apply on resultant colleges",
            "See Filter Ids"
          ],
          ["user_id", "User id for the target user. Optional.", ""],
          [
            "recommended",
            "If true, recommended colleges will be returned for the given user.",
            ""
          ]
        ]}
      />
    </FullBlock>
    <FullBlock>
      <h2 id="/v1/api/autocomplete/zip-code">
        GET /v1/api/autocomplete/zip-code
      </h2>
      <p>
        Autocompletes and provides additional info on zip codes in the United
        States.
      </p>
      <h3>Request Parameters</h3>
      <Table
        header={["Parameter", "Description", "Example"]}
        rows={[["*zipcode", "Zip code to check", "14420"]]}
      />
    </FullBlock>
    <FullBlock>
      <h2 id="/v1/api/autocomplete/high-schools">
        GET /v1/api/autocomplete/high-schools
      </h2>
      <p>
        Autocomplete high school names. This is useful if you have a form where
        a student should enter their high school.
      </p>
      <h3>Request Parameters</h3>
      <Table
        header={["Parameter", "Description", "Example"]}
        rows={[
          [
            "*q",
            "The user's input to the form field or the name of the high school to search for.",
            "Brockport"
          ],
          [
            "zipcode",
            "A zip code, this will significantly improve selection recommendations.",
            "14420"
          ]
        ]}
      />
    </FullBlock>
    <FullBlock>
      <h2 id="/v1/api/autocomplete/colleges">
        GET /v1/api/autocomplete/colleges
      </h2>
      <p>
        Search for a college by name, location or abbreviation. See an example
        in the{" "}
        <Link href="/json-api/guide--get-college-information">
          college information guide
        </Link>.
      </p>
      <h3>Request Parameters</h3>
      <Table
        header={["Parameter", "Description", "Example"]}
        rows={[["query", "Text to search colleges for.", "RPI"]]}
      />
    </FullBlock>
  </PageContent>
)
