// @flow

import React, { Fragment } from "react"
import * as commonRows from "../../shared-docs/user-interface/common-rows"

import UISimpleDoc from "../../components/UISimpleDoc"

import rpi from "@okcollege/postcore-data/production/colleges/rensselaer-polytechnic-institute.json"

const code1 = `
<div
  class="collegeai-acceptance-scatter-plot"
  data-college-unit-id="194824"
></div>
`.trim()

export const name = "Acceptance Scatter Plot"
export const section = "User Interface"
export const component = () => (
  <UISimpleDoc
    title="Acceptance Scatter Plot"
    description={
      <Fragment>
        <p>
          The acceptance scatter plot gives an idea of where on a GPA/SAT/ACT
          plot students are accepted and rejected. For some students, this gives
          a better intuition/understand about their changes of being accepted or
          rejected.
        </p>
        <p>
          The accept/reject scatter plots are based off over 200,000 anonymized
          students. Schools with many applicants have had the volume reduced and
          schools with less applicants have had some volume inferred. The volume
          for each school differs, so some plots may be more scarce.
        </p>
      </Fragment>
    }
    examples={[code1]}
    parameters={[commonRows.collegeId]}
  />
)
