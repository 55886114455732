// @flow

import React from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'
import Link from '../../components/Link'
import Code from 'react-syntax-highlighter'

export const name = 'User Tracking'
export const section = 'Overview'
export const component = () => (
  <PageContent>
    <HalfBlock>
      <h1>User Tracking</h1>
      <p>
        CollegeAI can track your users for you, handling all the data management
        and caching for a user's results. User data is accessible via the{' '}
        <Link href="/administration/dashboard">Administrator Dashboard</Link> ,
        the JSON API and the Browser API.
      </p>
      <p>User tracking is used for:</p>
      <ul>
        <li>Managing colleges on a user's list</li>
        <li>Saving user preferences</li>
        <li>Recommending colleges based on user preferences</li>
      </ul>
      <h2>Anonymous User Sessions</h2>
      <p>
        CollegeAI will automatically assign an anonymous-type user id when a
        user id is not specified. Anonymous-type user ids will not last more
        than a web session, so the user will not be able to recover any data
        entered or colleges added to their list within that session.
      </p>
      <h2>Saving/Loading User Information</h2>
      <p>
        If users sign up on your site, you can save their session by passing
        their user id to CollegeAI. CollegeAI will then load any previously
        saved preferences and/or colleges.
      </p>
      <p>With the User Interface, simply specify the following custom tag:</p>
      <Code language="html">
        {`
      <div class="collegeai-session-data" data-user-id="<INSERT USER ID>" />
      `.trim()}
      </Code>
      <p>Or the Browser API can be used:</p>
      <Code language="javascript">
        {`
          window.collegeai.updateUserId('<INSERT USER ID>')
      `.trim()}
      </Code>
      <p>
        You may only specify one user id per page. Repeatedly specifying the id
        will cause components on the page to reload.
      </p>
    </HalfBlock>
  </PageContent>
)
