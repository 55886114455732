// @flow

import React from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'

export const name = 'Getting Started'
export const order = 1
export const section = 'Overview'

export const component = () => (
  <PageContent>
    <HalfBlock>
      <h1>Getting Started</h1>
      <p>
        CollegeAI provides accurate college data, interactive college tools and
        advanced college search through easy-to-use web apis. Using CollegeAI
        APIs, you'll be able to...
      </p>
      <ul>
        <li>
          Access the latest college data across hundreds of reliable college
          data sources and directly from admissions pages.
        </li>
        <li>
          Display user interfaces for engaging students active in the college
          search process.
        </li>
        <li>
          Process user information to produce data-driven college
          recommendations.
        </li>
        <li>Track user actions and progress in the college search process.</li>
      </ul>
      <p>
        This documentation will walk through all of CollegeAI's functionality,
        give descriptive examples and usage details. If you have any issues with
        the documentation, use the intercom button at the bottom right corner of
        the page.
      </p>
      <p>
        To see the CollegeAI integration in action, check out{' '}
        <a href="https://collegesearchwebsite.com">collegesearchwebsite.com</a>.
        <br />
        <br />
        All the source code for the website is available{' '}
        <a href="https://collegesearchwebsite.com">on github</a>.
      </p>
    </HalfBlock>
  </PageContent>
)
