// @flow

import React, { PureComponent } from 'react'
import styles from '../../components/UIParameterTable/styles'
import { withStyles } from '../../utils'

import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from 'material-ui/Table'

class CustomTable extends PureComponent<{
  header: Array<string>,
  rows: Array<Array<string>>
}> {
  render() {
    const { classes, header, rows } = this.props
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {header.map(k => <TableCell key={k}>{k}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row}>
              {row.map(cell => <TableCell key={cell}>{cell}</TableCell>)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
}

export default withStyles(styles)(CustomTable)
