// @flow

import React from "react"
import PageContent from "../../components/PageContent"
import HalfBlock from "../../components/HalfBlock"
import Link from "../../components/Link"
import Code from "react-syntax-highlighter"
import DynamicAPISnippet from "../../components/DynamicAPISnippet"

export const name = "Setup"
export const order = 3
export const section = "Overview"
export const component = () => (
  <PageContent>
    <HalfBlock>
      <h1>Setup</h1>
      <h2>Getting Your API Keys</h2>
      <p>
        You can get an API key by hitting "Get My API Key" on the{" "}
        <a href="https://collegeai.com/data">CollegeAI Data page</a>.
      </p>
      <h2>User Interface</h2>
      <p>
        When using the User Interface API, the page must contain the following
        script line.
      </p>
      <Code language="html">
        {'<script src="https://api.collegeai.com/v1/api/bundle.js"></script>'}
      </Code>
      <p>
        This script will automatically update whenever there is a new version of
        the api available. Updates to the script should not break functionality
        on your site, however, if you'd like to maintain a fixed version, use
        the following script tag.
      </p>
      You must also specify your api key in a <i>collegeai-session-data</i>{" "}
      component somewhere on any page that uses any CollegeAI component.
      <Code language="html">
        {`<div class="collegeai collegeai-session-data"
  data-api-key="YOUR_API_KEY"
></div>`}
      </Code>
      <h2>Browser API</h2>
      If you are already using the User Interface, you can already use the
      Browser API without any other action. If you'd like to use the Browser API
      but not the user interface, use the following import.
      <Code language="html">
        {'<script src="https://api.collegeai.com/v1/api/window.js"></script>'}
      </Code>
      <h2>JSON API</h2>
      <p>
        Whenever making an API request, simply place your API key has a GET or
        POST parameter.
        <DynamicAPISnippet
          snippets={{
            window: params =>
              `window.collegeai.getCollegeAutocomplete("${params.query}")\n\n// Returns the response.collegeList as a Promise`
          }}
          endpoint="/autocomplete/colleges"
          method="GET"
          params={{ query: "MIT" }}
          editableParams={["query"]}
        />
      </p>
    </HalfBlock>
  </PageContent>
)
