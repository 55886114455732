// @flow

import React from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'
// import Link from '../../components/Link'

export const name = 'Sample Site'
export const section = 'Overview'
export const order = 4
export const component = () => (
  <PageContent>
    <HalfBlock>
      <h1>Sample Site</h1>
      <p>
        An example website,{' '}
        <a href="https://collegesearchwebsite.com">collegesearchwebsite.com</a>,
        utilizes the CollegeAI User Interface.
      </p>
      <p>
        You can view the source for the open-source reference website at{' '}
        <a href="https://github.com/CollegeAI/sample-college-search-website">
          this github repository
        </a>.
      </p>
    </HalfBlock>
  </PageContent>
)
