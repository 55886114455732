// @flow

import React, { Fragment } from "react"
import * as commonRows from "../../shared-docs/user-interface/common-rows"
import UISimpleDoc from "../../components/UISimpleDoc"

export const code = `
<div style="display: flex; flex-direction: row; flex: 1">

  <!-- COLLEGE LIST --!>
  <div
    class="collegeai-college-list"
    data-colleges-per-page="10"
    data-show-recommended="true"
  ></div>

</div>
`

export const name = "Example: Recommendations List"
export const order = 1000
export const section = "User Interface"
export const component = () => (
  <UISimpleDoc
    title={name}
    description={
      <Fragment>
        <p>
          CollegeAI provides a powerful recommendation API. The college list
          below is based on the responses you entered in the{" "}
          <a href="/v1/docs/user-interface/survey">Survey</a>. Try changing the
          Survey and seeing the changes reflected in the recommendations below.
        </p>
      </Fragment>
    }
    largeExample
    examples={[code]}
  />
)
