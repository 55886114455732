// @flow

import * as colors from 'material-ui/colors'

export default {
  a: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: colors.blue['600']
  },
  button: {}
}
