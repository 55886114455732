// @flow

import React, { Component } from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'
import FullBlock from '../../components/FullBlock'
import Table from '../../components/Table'
import papa from 'papaparse'

export const name = 'College Unit Ids'
export const section = 'Data Types'
export const order = 4

class CollegeUnitIdTable extends Component<*, *> {
  state = { infoStrings: null }
  componentDidMount = async () => {
    const csvRaw = await fetch('/college-ids.csv').then(t => t.text())
    const csvRows = await new Promise(resolve =>
      papa.parse(csvRaw, { complete: results => resolve(results.data) })
    )
    this.setState({
      infoStrings: csvRows.map(r => r.slice(1)).slice(1)
    })
  }
  render() {
    const { infoStrings } = this.state

    return (
      <PageContent>
        <HalfBlock>
          <h1>College Unit Ids</h1>
          <p>
            The table below shows all the college unit ids.{' '}
            <a href="/college-ids.csv">Download as a csv.</a>
          </p>
        </HalfBlock>
        <FullBlock>
          {infoStrings ? (
            <Table
              header={['College Name', 'Location', 'Unit Id']}
              rows={infoStrings}
            />
          ) : (
            <div>Loading College Table....</div>
          )}
        </FullBlock>
      </PageContent>
    )
  }
}

export const component = () => <CollegeUnitIdTable />
