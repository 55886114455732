// @flow

import * as colors from 'material-ui/colors'

export default {
  root: {
    backgroundColor: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: colors.blue['600'],
    boxShadow: '0px 1px 2px rgba(0,0,0,0.4)',
    zIndex: 200,
    display: 'flex',
    alignItems: 'center',
    '& .title': {
      color: '#fff',
      fontSize: 18
    }
  },
  underHeader: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row',
    '& .sidebar': {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      width: 300,
      backgroundColor: colors.grey['100'],
      borderRight: `1px solid ${colors.grey['300']}`,
      // borderLeft: `1px solid ${colors.grey['300']}`,
      minHeight: '100vh',
      '& .sectionHeader': {
        fontSize: 18,
        color: colors.grey['700'],
        borderBottom: `1px solid ${colors.grey['300']}`,
        paddingBottom: 2,
        marginTop: 10,
        marginBottom: 10,
        '&.active': {
          fontWeight: 700
        }
      },
      '& .link': {
        paddingLeft: 10,
        marginBottom: 4,
        '&.active': {
          fontWeight: 700
        }
      },
      '& .searchContainer': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        '& .icon': {
          marginRight: 8,
          color: colors.grey['600']
        }
      }
    },
    '& .content': {
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
      maxWidth: 'calc(100vw - 440px)',
      flexGrow: 1,
      flexShrink: 1,
      color: colors.grey['800'],
      position: 'relative',
      paddingBottom: 100,
      '& h1': {
        paddingBottom: 8,
        borderBottom: `1px solid ${colors.grey['300']}`
      },
      '& .sectionHeader': {
        position: 'absolute',
        right: 20,
        top: 20,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#333',
        color: '#fff',
        opacity: 0.5,
        fontSize: 11,
        fontWeight: 'bold',
        padding: 4,
        cursor: 'pointer',
        transition: 'opacity 500ms',
        '&:hover': {
          opacity: 1
        }
      },
      '& .bottomNavigation': {
        flexDirection: 'row',
        display: 'flex',
        borderTop: '1px solid #eee',
        paddingTop: 20,
        marginTop: 50,
        '& Button': {
          color: '#333',
          border: '1px solid #ddd',
          borderRadius: 4,
          '&:hover': {
            backgroundColor: '#eee'
          },
          '& .arrowleft': {
            paddingRight: 10,
            color: '#888'
          },
          '& .arrowright': {
            paddingLeft: 10,
            color: '#888'
          }
        }
      }
    }
  }
}
