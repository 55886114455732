// @flow

import React, { Fragment } from "react"
import * as commonRows from "../../shared-docs/user-interface/common-rows"
import UISimpleDoc from "../../components/UISimpleDoc"

export const code = `
<div style="display: flex; flex-direction: row; flex: 1">

  <!-- FILTER --!>
  <div class="collegeai-filter-view"></div>

  <!-- COLLEGE LIST --!>
  <div
    class="collegeai-college-list"
    data-colleges-per-page="10"
  ></div>

</div>
`

export const name = "Example: College Search Page"
export const order = 1000
export const section = "User Interface"
export const component = () => (
  <UISimpleDoc
    title={name}
    description={
      <Fragment>
        <p>
          One of the most common integrations with the CollegeAI API creates a
          college search with filtering on your web page.
        </p>
      </Fragment>
    }
    largeExample
    examples={[code]}
  />
)
