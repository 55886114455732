import * as colors from "material-ui/colors"

export default {
  exampleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    backgroundColor: colors.grey["100"],
    border: `1px dashed ${colors.grey["300"]}`,
    minHeight: 300
  }
}
