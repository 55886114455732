// @flow

import React, { Fragment } from 'react'
import * as commonRows from '../../shared-docs/user-interface/common-rows'
import UISimpleDoc from '../../components/UISimpleDoc'

export const code = `

`

export const name = 'Rich College Info'
export const section = 'User Interface  '
export const component = () => (
  <UISimpleDoc
    title={name}
    description={
      <Fragment>
        <p />
      </Fragment>
    }
    examples={[code]}
    parameters={[commonRows.userId, commonRows.collegeId]}
  />
)
