import * as colors from 'material-ui/colors'

export default {
  table: {
    width: 'calc(100% - 10px)',
    border: `1px solid ${colors.grey['300']}`,
    borderRight: `2px solid ${colors.grey['300']}`,
    borderBottom: `2px solid ${colors.grey['300']}`
  },
  rowNotImplemented: {
    opacity: 0.5
  }
}
