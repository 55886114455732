// @flow

import React, { Component } from "react"
import Code from "react-syntax-highlighter"
import ReactJSON from "react-json-view"
import Select from "material-ui/Select"
import TextField from "material-ui/TextField"
import { MenuItem } from "material-ui/Menu"

type Props = {
  endpoint: string,
  method: "GET" | "POST" | "DEL",
  params: Object,
  editableParams: Array<string>,
  snippets?: {
    [string]: (Object) => string
  }
}

type State = {
  params: Object,
  result?: Object,
  languagePreference: string
}

const encodeQuery = (o: Object) => {
  let queryString = ""
  for (const k in o) {
    queryString += `${encodeURIComponent(k)}=${encodeURIComponent(o[k])}&`
  }
  return queryString.slice(0, -1)
}

class DynamicAPISnippet extends Component<Props, *> {
  constructor(props: Props) {
    super()
    this.state = {
      params: props.params,
      languagePreference: window.localStorage.languagePreference || "curl"
    }
  }
  reloadResult = async params => {
    const { endpoint, method } = this.props
    if (!params) params = this.state.params
    if (method === "GET") {
      const response = await fetch(
        `/v1/api${endpoint}?${encodeQuery({
          api_key: "DO_NOT_USE_TESTING_KEY",
          ...params
        })}`
      ).then(r => r.json())
      this.setState({ result: response })
    }
  }
  componentDidMount() {
    this.reloadResult()
  }
  render() {
    const {
      method,
      endpoint,
      editableParams,
      result: defaultResult,
      snippets = {}
    } = this.props
    const { params, result = defaultResult, languagePreference } = this.state

    return (
      <div>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "row"
            }}
          >
            <Select
              onChange={e => {
                window.localStorage.languagePreference = e.target.value
                this.setState({ languagePreference: e.target.value })
              }}
              value={languagePreference}
            >
              <MenuItem value="curl">curl</MenuItem>
              <MenuItem value="javascript">javascript</MenuItem>
              {Object.keys(snippets).map(sn => (
                <MenuItem key={sn} value={sn}>
                  {sn}
                </MenuItem>
              ))}
            </Select>
            {editableParams.map(ep => (
              <TextField
                multiline
                InputLabelProps={{ shrink: true }}
                style={{ marginLeft: 10, marginRight: 10, flexGrow: 1 }}
                label={ep}
                value={params[ep]}
                onChange={e => {
                  const newParams = { ...params, [ep]: e.target.value }
                  this.setState({ params: newParams })
                  this.reloadResult(newParams)
                }}
              />
            ))}
          </div>
          <Code
            language={
              ["window", "javascript"].includes(languagePreference)
                ? "javascript"
                : "bash"
            }
          >
            {snippets[languagePreference]
              ? snippets[languagePreference](params)
              : languagePreference === "curl"
                ? `curl 'https://api.collegeai.com/v1/api${endpoint}?${encodeQuery(
                    {
                      api_key: "MY_API_KEY",
                      ...params
                    }
                  )}'`
                : `
// ES6 Javascript
fetch("https://api.collegeai.com/v1/api${endpoint}?${encodeQuery({
                    api_key: "MY_API_KEY",
                    ...params
                  })}")
  .then(r => r.json())
  .then(response => {
    console.log(response)
  })`.trim()}
          </Code>
          <div style={{ paddingTop: 10 }}>
            Result:
            <div style={{ backgroundColor: "#eee", padding: 10 }}>
              {result ? (
                <ReactJSON
                  name={null}
                  shouldCollapse={field =>
                    field.name === "root" ||
                    Object.keys(result).includes(field.name) ||
                    field.name === "0"
                      ? false
                      : true
                  }
                  displayDataTypes={false}
                  src={result}
                />
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DynamicAPISnippet
