// @flow

import React, { PureComponent, Fragment } from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'

import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from 'material-ui/Table'

import survey from '@okcollege/postcore-data/production/survey'

const surveyPageIds = [
  'future',
  'general-information',
  'interests',
  'location-sex',
  'random1',
  'athletics',
  'importance',
  'applied',
  'school-traits',
  'campus'
]
const surveyQuestionIds = [
  'major-category',
  'major-importance',
  'play-sports',
  'sport-level',
  'post-college',
  'vague-majors',
  'class-rank',
  'class-size',
  'tests-taken',
  'sat-score',
  'ap-tests',
  'psat-10',
  'preact-score',
  'favorite-class',
  'extracurricular-activities',
  'music-group',
  'what-sports',
  'school-clubs',
  'fast-filters',
  'faith',
  'study-amount',
  'challenging-offerings',
  'prefer-diversity',
  'preferred-region',
  'weather',
  'parents-college',
  'parent-colleges',
  'favorite-movie',
  'safety-importance',
  'college-professors',
  'applied-status',
  'college-status',
  'college-interested',
  'prefer-public',
  'school-size',
  'household-income',
  'campus-photos',
  'dorm-quality',
  'food-quality',
  'party-scene',
  'high-school-unweighted-gpa-value',
  'high-school-unweighted-gpa-total',
  'high-school-weighted-gpa',
  'high-school-location',
  'sat-score:math',
  'sat-score:reading',
  'act-score:reading',
  'act-score:science',
  'act-score:math',
  'act-score:english',
  'sat-subject-tests',
  'psat-10-math',
  'psat-8-9',
  'preact-score-reading',
  'preact-score-science',
  'preact-score-math',
  'preact-score:english',
  'likely-lunch-topics',
  'close-to-home',
  'preferred-region-importance',
  'care-about-weather',
  'living-area-type',
  'hot-guy-importance',
  'hot-girl-importance',
  'gender-ratio-importance',
  'high-school-name',
  'play-sports-importance',
  'watch-sports-importance',
  'importance-greek-life',
  'importance-highly-ranked',
  'importance-low-cost',
  'say-after-college'
]

export const name = 'Survey Question Ids'
export const section = 'Data Types'
export const order = 5

const questions = surveyPageIds.reduce((acc, pgId) => {
  const surveyPage = survey.pages[pgId]
  const qs = surveyPage.questions
    .map(q => {
      if (surveyQuestionIds.includes(q.id)) {
        if (
          q.type === 'choice' ||
          q.type === 'multichoice' ||
          q.type === 'numeric' ||
          q.type === 'location'
        ) {
          return [
            q.id,
            q.question,
            q.type,
            q.answerType,
            Boolean(q.hasOther),
            q.showIf || '',
            ''
          ]
        } else if (q.type === 'extended-multichoice') {
          return [
            q.id,
            q.question,
            q.type,
            q.answerType,
            Boolean(q.hasOther),
            q.showIf || '',
            q.extendedAnswerType || ''
          ]
        }
      }
      return undefined
    })
    .filter(Boolean)
  return acc.concat(qs)
}, [])

class QuestionIdTable extends PureComponent<Props> {
  render = () => {
    const { classes = {} } = this.props
    return (
      <PageContent>
        <HalfBlock>
          <h1>Question Ids</h1>
          <p>The table below shows all survey question ids.</p>
        </HalfBlock>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Question ID</TableCell>
              <TableCell>Question Text</TableCell>
              <TableCell>Question Type</TableCell>
              <TableCell>Answer Type</TableCell>
              <TableCell>Has Other Field</TableCell>
              <TableCell>Conditional Display</TableCell>
              <TableCell>Extended Answer Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map(
              (
                [
                  questionId,
                  question,
                  type,
                  answerType,
                  hasOther,
                  showIf,
                  extendedAnswerType,
                  implemented = true
                ],
                i
              ) => (
                <TableRow
                  key={i}
                  className={
                    implemented === false ? classes.rowNotImplemented : ''
                  }
                >
                  <TableCell>
                    {questionId}
                    {implemented === false ? <i> (planned)</i> : ''}
                  </TableCell>
                  <TableCell>{question}</TableCell>
                  <TableCell>{type}</TableCell>
                  <TableCell>{answerType}</TableCell>
                  <TableCell>{hasOther ? 'true' : 'false'}</TableCell>
                  <TableCell>{showIf}</TableCell>
                  <TableCell>{extendedAnswerType}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        <i style={{ fontSize: 11, paddingLeft: 30 }}>* required parameter</i>
      </PageContent>
    )
  }
}

export const component = () => <QuestionIdTable />
export default QuestionIdTable
