// @flow

import React, { Fragment } from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'
import FullBlock from '../../components/FullBlock'
import Table from '../../components/Table'
import Link from '../../components/Link'
import DynamicAPISnippet from '../../components/DynamicAPISnippet'

export const name = 'Guide: Get College Information'
export const section = 'JSON API'
export const order = 3
export const component = () => (
  <PageContent>
    <FullBlock>
      <h1>Guide: Get College Information</h1>
      <p>This guide will walkthrough retrieving college information.</p>
      <p>
        First we'll explore how to retrieve the <b>unit ids</b> and{' '}
        <b>semantic ids</b> for colleges. Then we'll use one of the ids to
        retrieve information for one or more colleges. The semantic ids have the
        college name within them. The unit ids are numeric. An example of a
        semantic id is <code>rensselaer-polytechnic-institute</code>. An example
        unit id is <code>194824</code>.
      </p>
    </FullBlock>
    <FullBlock>
      <h2>College Name Search and Finding College Ids</h2>
    </FullBlock>
    <FullBlock
      left={
        <Fragment>
          <p>
            We can retrieve the semantic id and unit id for a college using it's
            name or abbreviation by performing a query to{' '}
            <code>/api/autocomplete/colleges</code> as shown in the example.
          </p>
        </Fragment>
      }
      right={
        <DynamicAPISnippet
          snippets={{
            window: params =>
              `window.collegeai.getCollegeAutocomplete("${
                params.query
              }")\n\n// Returns the response.collegeList as a Promise`
          }}
          endpoint="/autocomplete/colleges"
          method="GET"
          params={{ query: 'MIT' }}
          editableParams={['query']}
        />
      }
    />
    <FullBlock>
      <h2>Getting College Information</h2>
    </FullBlock>
    <FullBlock
      left={
        <Fragment>
          <p>
            We can use the semantic ids to retrieve college information using{' '}
            <code>/api/college/info</code>. We must specify information ids for
            each piece of information we would like returned.{' '}
            <Link href="/data-types/info-ids">
              See all available information ids.
            </Link>
          </p>
          <p>
            Note that we're specifying multiple college ids by separating each
            id with a comma. This is much faster than individually requesting
            college ids.
          </p>
        </Fragment>
      }
      right={
        <Fragment>
          <DynamicAPISnippet
            snippets={{
              window: params =>
                `window.collegeai.getCollegeInfo([\n${params.college_ids
                  .split(',')
                  .map(id => `  "${id}"`)
                  .join(',\n')}\n], [\n${params.info_ids
                  .split(',')
                  .map(id => `  "${id}"`)
                  .join(
                    ',\n'
                  )}\n])\n\n// Returns the response.colleges as a Promise`
            }}
            endpoint="/college/info"
            method="GET"
            params={{
              college_ids:
                'rensselaer-polytechnic-institute,massachusetts-institute-of-technology',
              info_ids: 'city,in_state_tuition,website'
            }}
            editableParams={['college_ids', 'info_ids']}
          />
        </Fragment>
      }
    />
    <FullBlock
      left={
        <Fragment>
          <p>You can also query using the college unit ids.</p>
        </Fragment>
      }
      right={
        <Fragment>
          <DynamicAPISnippet
            snippets={{
              window: params =>
                `window.collegeai.getCollegeInfoUsingUnitIds([\n${params.college_unit_ids
                  .split(',')
                  .map(id => `  "${id}"`)
                  .join(',\n')}\n], [\n${params.info_ids
                  .split(',')
                  .map(id => `  "${id}"`)
                  .join(
                    ',\n'
                  )}\n])\n\n// Returns the response.colleges as a Promise`
            }}
            endpoint="/college/info"
            method="GET"
            params={{
              college_unit_ids: '194824,166683',
              info_ids: 'city,in_state_tuition,website'
            }}
            editableParams={['college_unit_ids', 'info_ids']}
          />
        </Fragment>
      }
    />
    <FullBlock
      left={
        <Fragment>
          <p>
            Querying by college names or abbreviations is also possible. Note
            that some colleges have similar names, the best match will be
            returned.
          </p>
        </Fragment>
      }
      right={
        <Fragment>
          <DynamicAPISnippet
            snippets={{
              window: params =>
                `window.collegeai.getCollegeInfoUsingNames([\n${params.college_names
                  .split(',')
                  .map(id => `  "${id}"`)
                  .join(',\n')}\n], [\n${params.info_ids
                  .split(',')
                  .map(id => `  "${id}"`)
                  .join(
                    ',\n'
                  )}\n])\n\n// Returns the response.colleges as a Promise`
            }}
            endpoint="/college/info"
            method="GET"
            params={{
              college_names: 'RPI,MIT',
              info_ids: 'city,in_state_tuition,website'
            }}
            editableParams={['college_names', 'info_ids']}
          />
        </Fragment>
      }
    />
  </PageContent>
)
