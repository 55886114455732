// @flow

import React, { Fragment } from 'react'
import * as commonRows from '../../shared-docs/user-interface/common-rows'
import UISimpleDoc from '../../components/UISimpleDoc'
import Link from '../../components/Link'
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from 'material-ui/Table'
import * as colors from 'material-ui/colors'

export const code = `
<div
  class="collegeai collegeai-college-info-table"
  data-info-ids="student-faculty-ratio,average-net-cost"
  data-college-unit-id="194824"
  data-show-header="true"
></div>
`

export const name = 'College Info Table'
export const section = 'User Interface'
export const component = () => (
  <UISimpleDoc
    title={name}
    description={
      <Fragment>
        <p>
          A college info table helps with displaying college information that
          does not lend itself to visual components or isn't important enough to
          warrant a large visual component.
        </p>
        <p>
          See <Link href="/json-api/info-ids">/json-api/info-ids</Link> to
          curate the information for your table.
        </p>
      </Fragment>
    }
    examples={[code]}
    parameters={[
      commonRows.collegeId,
      {
        name: 'data-info-ids',
        description: 'List of info ids to show in the table.',
        example: 'student-faculty-ratio,average-net-cost'
      },
      {
        name: 'show-header',
        description:
          'Show a header with "Name" and "Value". Defaults to false.',
        example: 'false'
      }
    ]}
  />
)
