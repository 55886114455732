// @flow

import React from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'
import UIParamterTable from '../../components/UIParameterTable'

export const name = 'Reason Ids'
export const section = 'Data Types'
export const order = 7
const infoStrings = [
  [
    'Student Faculty Ratio',
    'Rensselaer Polytechnic Institute has one faculty member for every 15 students.'
  ],
  [
    'School Size',
    'Amherst College has a Small student population (~1700 students).'
  ],
  [
    'School Athletics',
    'Syracuse University offers ice hockey at the NCAA Division I level for women.'
  ],
  [
    'SAT Score',
    'Your math score is 720, which is 5 points lower than the average accepted score of 725 at Rensselaer Polytechnic Institute'
  ],
  [
    'ACT Score',
    'Your math score is 32, which is 1 point lower than the average accepted score of 33 at Stanford University'
  ],
  [
    'Princeton Review, Best Colleges',
    'Rensselaer Polytechnic Institute is listed in the 2018 edition of "The Best 382 Colleges" by the Princeton Review.'
  ],
  [
    'Religion',
    'Boston College has a religious affiliation with Roman Catholic',
    'Religion uses the list religious affiliation of a school or if not listed, the associated religion.'
  ],
  [
    'Desired Region',
    'Williams College is in one of your preferred regions, New England (CT, ME, MA, NH, RI, VT).'
  ],
  [
    'Professor Quality',
    "Rensselaer Polytechnic Institute's professors are ranked 85th in the nation",
    "Professor Quality is a proprietary ranking of the school's professors"
  ],

  [
    'Campus Safety',
    'Rensselaer Polytechnic Institute is rated 95th safest college in the country',
    'Campus Safety is a proprietary ranking of the school safety'
  ],
  [
    'Best in Nation',
    'Rensselaer Polytechnic Institute is ranked in the Top 50 for best colleges in the nation.',
    'Campus Safety is a proprietary ranking of the all schools in the nation'
  ],
  [
    'Campus Party Scene',
    'Syracuse University is ranked 31st for partying in the country',
    'Campus Party Scene is a proprietary ranking'
  ],
  [
    'Major Ranking',
    'Williams College is ranked in the Top 50 for Computer Science. Williams College is ranked worse than Rensselaer Polytechnic Institute which ranks higher in the Top 25.',
    'Ranking in the following categories: Art, Humanities, Business, Political, Economics, Accounting, Communications, Health, Public, Math, Environmental, Computer, Chemistry, Biology, Education, Engineering, English, History, Psychology'
  ],
  [
    'Activities and Organizations',
    'Williams College has a program for student run newpapers.',
    'Activity/organization provided by the university: Choral Groups, Student Government, Model UN, Student Newspaper, Student Run Film Organization, Drama/Theater Student Organization, Television Station, Yearbook Club, Campus Ministry, Literary Magazine Program, Radio Station, Marching Band, Concert Band, Dance, Music Ensembles, Musical Theater, Symphony Orchestra, International Student Organization, Opera, Jazz Band, Pep Band'
  ],
  [
    'LGBTQ Friendliness',
    'Williams College is ranked 4.5/5 for their LGBTQ friendliness according to Campus Pride Index.',
    'The ranking for lgbtq friendliness given out of 5 according to Campus Pride Index'
  ],

  [
    'Best Value',
    'Rensselaer Polytechnic Institute is ranked in the Top 25 for Best Value.',
    'Best Value is a proprietary ranking'
  ],
  [
    'Best Undergraduate',
    'Rensselaer Polytechnic Institute is ranked in the Top 100 for best undergraduate colleges.',
    'Best Undergraduate is a proprietary ranking'
  ],
  [
    'Average Total Cost',
    'For the average student, Rensselaer Polytechnic Institute costs ~ $62000 each year.'
  ],
  [
    'Greek Life',
    'Syracuse University is ranked 77th for greek life in the country',
    'Greek Life is a proprietary ranking'
  ],
  [
    'Gender Ratio',
    'Rensselaer Polytechnic Institute has an uneven gender ratio with a student gender distribution of 69.5% men and 30.5% women.'
  ],
  [
    'Colleges That Change Lives',
    'Clark University is listed in the book "Colleges that Change Lives" by Loren Pope.'
  ],
  [
    'Campus Dorm Quality',
    "Williams College's dorm quality is ranked 46th in the nation",
    'Campus Dorm Quality is a proprietary ranking'
  ],
  ['State', 'Stanford University is in California, not New York.'],
  [
    'Closeness to Home',
    'Clark University is < 1 hour drive from home.',
    "The drive time to the university based on the user's location as given in the survey"
  ]
].map(([infoId, example, description]) => [
  infoId.replace(/(\s|,)/g, '-').toLowerCase(),
  infoId,
  example,
  description
])
export const component = () => (
  <PageContent>
    <HalfBlock>
      <h1>Reason Ids</h1>
      <p>The table below shows all the reason ids.</p>
    </HalfBlock>
    <UIParamterTable
      rows={infoStrings.map(([infoId, name, example, description]) => ({
        name: infoId,
        description,
        optional: true,
        example
      }))}
    />
  </PageContent>
)
