// @flow

import React, { Component, Fragment } from "react"
import { withStyles } from "../../utils"
import styles from "./styles"
import Typography from "material-ui/Typography"
import CenteredContent from "../CenteredContent"
import Link from "../Link"
import TextField from "material-ui/TextField"
import Button from "material-ui/Button"
import SearchIcon from "material-ui-icons/Search"
import BackwardArrow from "material-ui-icons/ArrowBack"
import ForwardArrow from "material-ui-icons/ArrowForward"

type State = {
  searchFieldText: string
}

export type Props = {
  classes?: any,
  children?: any,
  pages: Array<{ name: string, slug: string, section: string }>,
  currentPage: { name: string, slug: string, section: string }
}

class PageContainer extends Component<Props, State> {
  state: State = { searchFieldText: "" }
  render = () => {
    const { classes = {}, pages, children, currentPage } = this.props
    const { searchFieldText } = this.state

    const matchingPages = pages.filter(
      pg =>
        !searchFieldText ||
        `${pg.section} ${pg.name}`
          .toLowerCase()
          .includes(searchFieldText.toLowerCase())
    )

    const pageNumber = pages.indexOf(currentPage)

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography className="title">
            CollegeAI API V1 Documentation
          </Typography>
          <div style={{ flexGrow: 1 }} />
          <Button style={{ color: "#fff" }} href="/overview/getting-started">
            Go to V0 Docs
          </Button>
        </div>
        <div className={classes.underHeader}>
          <div className="sidebar">
            <div className="searchContainer">
              <SearchIcon className="icon" />
              <TextField
                placeholder="Search API Docs"
                onChange={({ target: { value: searchFieldText } }) =>
                  this.setState({ searchFieldText })
                }
                className="textField"
              />
            </div>
            {matchingPages.filter(pg => pg.display).map((pg, i) => (
              <Fragment key={pg.slug}>
                {(i === 0 || pg.section !== matchingPages[i - 1].section) && (
                  <Typography
                    className={
                      "sectionHeader" +
                      (currentPage.section === pg.section ? " active" : "")
                    }
                  >
                    {pg.section}
                  </Typography>
                )}
                <Link
                  className={"link" + (currentPage === pg ? " active" : "")}
                  href={`${pg.slug}`}
                >
                  {pg.name}
                </Link>
              </Fragment>
            ))}
          </div>
          <div className="content">
            <div className="sectionHeader">{currentPage.section}</div>
            {children}
            <div className="bottomNavigation">
              {pageNumber > 0 && (
                <Link href={pages[pageNumber - 1].slug}>
                  <Button>
                    <BackwardArrow className="arrowleft" />
                    {pages[pageNumber - 1].name}
                  </Button>
                </Link>
              )}
              <div style={{ flexGrow: 1 }} />
              {pageNumber < pages.length - 1 && (
                <Link href={pages[pageNumber + 1].slug}>
                  <Button>
                    <span style={{ paddingRight: "4px" }}>Continue to</span>
                    {pages[pageNumber + 1].section !== currentPage.section
                      ? pages[pageNumber + 1].section
                      : pages[pageNumber + 1].name}{" "}
                    <ForwardArrow className="arrowright" />
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(PageContainer)
