// @flow

import React, { Fragment } from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'
import FullBlock from '../../components/FullBlock'
import Table from '../../components/Table'
import Link from '../../components/Link'
import DynamicAPISnippet from '../../components/DynamicAPISnippet'

export const name = 'Guide: Search and Filter Colleges'
export const section = 'JSON API'
export const order = 4
export const component = () => (
  <PageContent>
    <FullBlock>
      <h1>Guide: Search and Filter Colleges</h1>
      <p>
        This guide will walkthrough searching for colleges via the JSON API.
      </p>
    </FullBlock>
    <FullBlock>
      <h2>Perform a search query using a filter.</h2>
    </FullBlock>
    <FullBlock
      left={
        <Fragment>
          <p>
            The example code on the right shows an example of a complex filter
            query where we're try to find a college matching the following
            criteria...
            <ul>
              <li>Public Universities</li>
              <li>Large</li>
              <li>Within 500 miles of the zip code 02111</li>
              <li>That's close to an overall SAT score of 1200</li>
            </ul>
            See how you can customize the filter more on the{' '}
            <a href="/data-types/filter-ids">filter data types page</a>. Note
            that filter ids can be specified with camel-case or dashes.
          </p>
        </Fragment>
      }
      right={
        <Fragment>
          <DynamicAPISnippet
            snippets={{
              window: params =>
                `window.collegeai.searchColleges({
  filters: ${params.filters},
  info_ids: [${params.info_ids
    .split(',')
    .map(a => `"${a}"`)
    .join(',')}],
  limit: 10
})\n\n// Returns the response.colleges as a Promise`
            }}
            endpoint="/college-list"
            method="GET"
            params={{
              filters: `{\n"funding-type":["public"],\n"schoolSize":["large"],\n"zipCode":"02111",\n"distanceFromHomeMiles":[0,500],\n"satOverall":1200,\n"closeToMyScores":true\n}`,
              info_ids:
                'website,shortDescription,longDescription,campusImage,city,stateAbbr,aliases,colors,locationLong,locationLat'
            }}
            editableParams={['info_ids', 'filters']}
          />
        </Fragment>
      }
    />
    <FullBlock
      left={
        <Fragment>
          <p>
            To design a filter object, first your desired filters from the{' '}
            <a href="/data-types/filter-ids">filter ids page</a>.<br />
          </p>
          <p>
            Add each filter to your filter object. Each filter in the filter
            object acts as an AND. If you add two filters, both must be true for
            for a college to appear in the resultant list.
          </p>
        </Fragment>
      }
    />
  </PageContent>
)
