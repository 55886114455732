// @flow

import React from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'
import Link from '../../components/Link'

export const name = 'Concepts'
export const order = 2
export const section = 'Overview'
export const component = () => (
  <PageContent>
    <HalfBlock>
      <h1>Concepts</h1>
      <p>There are three ways of accessing CollegeAI functionality:</p>
      <ul>
        <li>
          Using the <Link href="/user-interface/overview">User Interface</Link>
        </li>
        <li>
          Using the <Link href="/json-api/overview">JSON API</Link>
        </li>
        <li>
          Using the <Link href="/browser-api/overview">Browser API</Link>
        </li>
      </ul>
      <h2>User Interface</h2>
      <p>
        CollegeAI User Interface integrations place CollegeAI widgets directly
        on a website. This is the simplest method to get started and requires
        very little programming (just copy and pasting lines of HTML). All the
        pages on <Link href="https://collegeai.com">collegeai.com</Link> are
        available to display.
      </p>

      <h3>User Data and Tracking</h3>

      <p>
        CollegeAI will collect data the user enters to provide functionality to
        that user. For example, if a user answers a survey question, CollegeAI
        will adjust college recommendations to best match that user. Whenever
        CollegeAI collects data in the User Interface, an{' '}
        <Link href="/browser-api/events">event</Link> is released that you can
        capture to collect the data on your site. You can also access user
        information using Administrator Tools.
      </p>

      <h2>Browser API</h2>
      <p>
        The Browser API is a way to interact with the CollegeAI Widgets on your
        page using Javascript. Use this when you want to collect information
        about users in realtime or you want to offer the user custom
        functionality that is not available on{' '}
        <Link href="https://collegeai.com">collegeai.com</Link>.
      </p>

      <h2>JSON API</h2>
      <p>
        All the data displayed on{' '}
        <Link href="https://collegeai.com">collegeai.com</Link> is available via
        the JSON API. Use the JSON API when you have custom requirements for
        displaying and interacting with college data, or you need to download
        data for processing.
      </p>
    </HalfBlock>
  </PageContent>
)
