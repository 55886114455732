// @flow

import React, { PureComponent, Component, Fragment } from "react"
import PageContainer from "./components/PageContainer"
import {
  createMuiTheme,
  MuiThemeProvider,
  createGenerateClassName
} from "material-ui/styles"
import JssProvider from "react-jss/lib/JssProvider"
import { SheetsRegistry } from "jss"
import themeObject from "./theme"
import { slugify } from "./utils"
import PropTypes from "prop-types"
import "typeface-roboto"
import { history } from "./"

const importAll = r => r.keys().map(r)
const pageImports = importAll(require.context("./pages", true, /\.js$/))

const sheetsRegistry = new SheetsRegistry()

const generateClassName = createGenerateClassName({
  productionPrefix: "collegeai-papi-docs-styles"
})

const sections = [
  "Overview",
  "User Interface",
  "JSON API",
  "Browser API",
  "Administration",
  "Data Types",
  "Legal"
]

export const pages = pageImports
  .filter(pg => pg.name && pg.section && pg.component)
  .filter(pg => sections.includes(pg.section))
  .sort(
    (a, b) =>
      (sections.indexOf(a.section) - sections.indexOf(b.section)) * 10000 +
      (a.order || 100) -
      (b.order || 100)
  )
  .map(pg => ({
    name: pg.name,
    section: pg.section,
    slug: `/v1/docs/${slugify(pg.section)}/${slugify(pg.name)}`,
    display: pg.display === undefined ? true : pg.display,
    component: pg.component
  }))

const theme = createMuiTheme(themeObject)

type State = { path?: string }

class LinkProvider extends PureComponent<{
  linkProviderOnClick: string => any,
  children: any
}> {
  getChildContext() {
    return { linkProviderOnClick: this.props.linkProviderOnClick }
  }

  render = () => {
    const { linkProviderOnClick, children } = this.props

    return <div>{children}</div>
  }
}
LinkProvider.childContextTypes = { linkProviderOnClick: PropTypes.func }

class App extends Component<*, State> {
  state: State = {}
  unlisten: ?Function

  componentDidMount() {
    // Listen to history
    if (!window.location.href.includes("v1")) {
      window.location.href = "/v1/docs"
    }
    this.unlisten = history.listen((location, action) => {
      if (location.pathname !== this.state.path)
        this.setState({ path: location.pathname })
    })
  }
  componentWillUnmount() {
    // Unlisten to history
    this.unlisten && this.unlisten()
    this.unlisten = null
  }

  onChangePath = (path: string) => {
    window.scrollTo(0, 0)
    history.push(path)
    this.setState({ path })
  }

  render() {
    let path = this.state.path || window.location.pathname
    if (path === "/v1") path = "/v1/docs/overview/getting-started"
    if (path === "/v1/docs") path = "/v1/docs/overview/getting-started"
    const page = pages.find(pg => pg.slug === path)

    if (!page) {
      return (
        <div style={{ fontSize: 24, textAlign: "center", padding: 100 }}>
          Page Not Found
          <div>
            <a href="/v1/docs">Go To V1 API Docs</a>
          </div>
        </div>
      )
    }

    return (
      <LinkProvider linkProviderOnClick={this.onChangePath}>
        <JssProvider
          registry={sheetsRegistry}
          generateClassName={generateClassName}
        >
          <MuiThemeProvider theme={theme}>
            <PageContainer pages={pages} currentPage={page}>
              {page ? <page.component /> : "Page Not Found"}
            </PageContainer>
          </MuiThemeProvider>
        </JssProvider>
      </LinkProvider>
    )
  }
}

export default App
