// @flow

import React from 'react'
import PageContent from '../../components/PageContent'
import HalfBlock from '../../components/HalfBlock'

export const name = 'Updating Users'
export const section = 'Administration'
export const component = () => (
  <PageContent>
    <HalfBlock>
      <h1>{name}</h1>
      <p>In progress.</p>
    </HalfBlock>
  </PageContent>
)
